.custom-node-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
}

.ports-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.cipher-text-input-class {
  height: 215px;
  width: 900px;
}
.ascii-input-class {
  height: 130px;
  width: 270px;
}
.and-class {
  height: 100px;
  width: 105px;
}
.or-class {
  height: 100px;
  width: 105px;
}
.not-class {
  height: 100px;
  width: 105px;
}
.xor-class {
  height: 100px;
  width: 105px;
}
.key-class {
  height: 97px;
  width: 110px;
}
.aes-decrypt-class {
  height: 540px;
  width: 900px;
}
.result-class {
  height: 135px;
  width: 700px;
}